import { createStore } from 'redux';
import rootReducer from '.'; // export default of index.js

// Make language reducer persisted
const saveLanguageToLocalStorage = state => {
  try {
    const serializedState = JSON.stringify(state.language);
    if(typeof window !== 'undefined') {
      localStorage.setItem('language', serializedState);
    }
  } catch(err) {
    console.log(err);
  }
}

const loadLanguageFromLocalStorage = () => {
  try {
   
    if(typeof window !== 'undefined') { 
     const serializedState = localStorage.getItem('language');
    
    if(serializedState === null) {
      return undefined 
    } else {
      return JSON.parse(serializedState)
    }
  }
  } catch(err) {
    console.log(err);
  }
}

// Load only language reducer as preloaded state for redux. other reducers will fallback to their initial state
let persistedState = {}
persistedState.language = loadLanguageFromLocalStorage();

// CreateStore takes preloadedstate as second argument
const store = createStore(rootReducer, persistedState);

store.subscribe(() => saveLanguageToLocalStorage(store.getState()));

export default store;