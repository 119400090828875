import * as R from "ramda"
// Initial state for app reducer
const initialState = {
  popUpOpen: false,
  filtersOpen: false,
  currentSpot: {},
  allSpots: [],
  filteredSpots: [],
  activeFilter: ["Kaikki"],
  counts: {},
  materials: [],
  currentCenter: [60.28, 24.97],
  currentZoom: 8,
  wasSearch: false,
}

// REGISTER ACTION TYPES
const OPEN_POPUP = "OPEN_POPUP"
const CLOSE_POPUP = "CLOSE_POPUP"
const OPEN_FILTERS = "OPEN_FILTERS"
const CLOSE_FILTERS = "CLOSE_FILTERS"
const INIT_SPOTS = "INIT_SPOTS"
const FILTER_SPOTS = "FILTER_SPOTS"
const COUNT_MATERIALS = "COUNT_MATERIALS"
const INIT_MATERIALS = "INIT_MATERIALS"
const SET_MAP_CENTER = "SET_MAP_CENTER"
const RESET_SEARCH = "RESET_SEARCH"
const INIT_SPOTS_FROM_URL = "INIT_SPOTS_FROM_URL"
// ACTIONS -------------------------------------------- |

// Open and close popups -------------------
export const openPopUp = spot => {
  return {
    type: OPEN_POPUP,
    spot,
  }
}
export const closePopUp = () => ({
  type: CLOSE_POPUP,
})
// -----------------------------------------

// Open and close filters -------------------
export const openFilters = () => {
  return {
    type: OPEN_FILTERS,
  }
}
export const closeFilters = () => ({
  type: CLOSE_FILTERS,
})
// -----------------------------------------

// Count materials -------------------------
export const countMaterials = spots => {
  let materials = spots.map(spot => {
    return spot.node.materials
  })
  // merge arrays inside array to one array of objects
  const flatMaterials = []
  materials.forEach(material => {
    flatMaterials.push(...material)
  })

  materials = flatMaterials.map(material => material.code)

  let uniqueMaterials = new Set(materials)
  uniqueMaterials = Array.from(uniqueMaterials)
  let counts = uniqueMaterials.map(unique => {
    const number = materials.filter(v => v === unique).length
    const count = { code: "code" + unique, count: number }
    return count
  })
  counts = counts.reduce((obj, item) => {
    obj[item.code] = item
    return obj
  }, {})
  counts.all = { count: spots.length }

  return {
    type: COUNT_MATERIALS,
    counts,
  }
}
// -----------------------------------------

// Initialize spots to state ---------------
export const initSpots = spots => {
  return {
    type: INIT_SPOTS,
    spots,
  }
}

// -----------------------------------------

// Filter spots ----------------------------
export const filterSpots = (filterValue, allspots, activeFilter) => {
  let filters = [...activeFilter]

  if (filters.includes("Kaikki")) {
    let index = filters.indexOf("Kaikki")
    filters.splice(index, 1)
  }

  if (filters.includes(filterValue)) {
    let index = filters.indexOf(filterValue)
    filters.splice(index, 1)
  } else {
    filters.push(filterValue)
  }

  let spots = []
  const hasIn = spot => {
    let found = false
    if (spot.node.materials) {
      let materials = spot.node.materials.map(material => {
        return material["code"]
      })
      const checkIfIncluded = filter => {
        return materials.includes(filter)
      }
      found = filters.every(checkIfIncluded)
    }
    return found
  }
  if (filters.length <= 1 && filters.includes("Kaikki")) {
    spots = allspots
  } else {
    spots = R.filter(hasIn, allspots)
  }

  return {
    type: FILTER_SPOTS,
    spots,
    filters,
  }
}

// Initilize and filter spots from url params ----------------------------
export const initSpotsFromUrl = (filters, allspots) => {
  let spots = []
  const hasIn = spot => {
    let found = false
    if (spot.node.materials) {
      let materials = spot.node.materials.map(material => {
        return material["code"]
      })
      const checkIfIncluded = filter => {
        return materials.includes(filter)
      }
      found = filters.every(checkIfIncluded)
    }
    return found
  }
  if (!filters || (filters.length <= 1 && filters.includes("Kaikki"))) {
    spots = allspots
  } else {
    spots = R.filter(hasIn, allspots)
  }

  return {
    type: INIT_SPOTS_FROM_URL,
    spots,
    filters,
  }
}
// -----------------------------------------

// Init materials to state -------------------
export const initMaterials = materials => {
  materials.sort(function(a, b) {
    var nameA = a.node.name.toUpperCase() // ignore upper and lowercase
    var nameB = b.node.name.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }

    // names must be equal
    return 0
  })

  return {
    type: INIT_MATERIALS,
    materials,
  }
}

// -----------------------------------------

// Set map center and zoom -------------------
export const setMapCenter = (center, zoom, search) => {
  let wasSearch = false
  if (search) {
    wasSearch = true
  }

  const mapcenter = [
    parseFloat(center.lat.toFixed(4)),
    parseFloat(center.lng.toFixed(4)),
  ]

  return {
    type: SET_MAP_CENTER,
    mapcenter,
    zoom,
    wasSearch,
  }
}

// Reset search (wasSearch value) -------------------
export const resetSearch = () => {
  return {
    type: RESET_SEARCH,
  }
}

// -----------------------------------------

// ACTIONS END --------------------------------------- |

// APP REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_POPUP:
      return { ...state, popUpOpen: true, currentSpot: action.spot }
    case CLOSE_POPUP:
      return { ...state, popUpOpen: false }
    case OPEN_FILTERS:
      return { ...state, filtersOpen: true }
    case CLOSE_FILTERS:
      return { ...state, filtersOpen: false }
    case INIT_SPOTS:
      return {
        ...state,
        allSpots: action.spots,
        filteredSpots: action.spots,
        activeFilter: ["Kaikki"],
      }
    case FILTER_SPOTS:
      return {
        ...state,
        filteredSpots: action.spots,
        activeFilter: action.filters,
      }
    case INIT_SPOTS_FROM_URL:
      return {
        ...state,
        allSpots: action.spots,
        filteredSpots: action.spots,
        activeFilter: [action.filters],
      }
    case COUNT_MATERIALS:
      return { ...state, counts: action.counts }
    case INIT_MATERIALS:
      return { ...state, materials: action.materials }
    case RESET_SEARCH:
      return { ...state, wasSearch: false }
    case SET_MAP_CENTER:
      return {
        ...state,
        currentCenter: action.mapcenter,
        currentZoom: action.zoom,
        wasSearch: action.wasSearch,
      }
    default:
      return state
  }
}
