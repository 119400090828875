//import { persistedState } from './ReduxWrapper'
// Initial state for language reducer
const initialState =  {language: 'fi'};


// REGISTER ACTION TYPES
const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// ACTIONS -------------------------------------------- |


// Change language -------------------------
export const changeLanguage = lang => {
  return ({
  type: CHANGE_LANGUAGE, lang
})};

// -----------------------------------------

// ACTIONS END --------------------------------------- |


// LANGUAGE REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return { ...state, language: action.lang };
    default:
      return state;
  }
};
